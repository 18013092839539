import React, {Component} from 'react';
import NavigateBar from '../../components/NavigateBar/NavigateBar';
import './DataManage.scss';
import Footer from "../../components/Footer/Footer";
import Pagination from "../../components/Pagination/Pagination";
import axios from "../../service/http";
import Tips from "../../components/Tips/Tips";
import Alert from "../../components/Alert/Alert";
import Loader from "../../components/Loader/Loader";
import I18N, {i18n} from "../../components/Util/I18nUtils";
import {userLanguage} from "../../components/Util/tool";
import PagingTable from "../../components/PagingTable/PagingTable";
import deleteIcon from '../../assets/images/delete.svg';
let _that;
export default class DataManage extends Component {

    constructor(props) {
        super(props);
        _that = this;
        this.pageRef = React.createRef();
        this.btnRef = React.createRef();
        this.activeTable = "query";
        this.PAGE_SIZE = 15;
        this.CURR_PAGE = 1;

        this.COLUMNINFO_QUERY = [
            {
                name: i18n(I18N.SAAS_DATA_QUERY_NAME),
                key: "queryName"
            },
            {
                name: i18n(I18N.SAAS_DATA_QUERY_TYPE),
                key: "queryType",
                style: {
                    width: '130px',
                }
            }, {
                name: i18n(I18N.SAAS_DATA_QUERY_PATH),
                key: "queryPath"
            }, {
                name: i18n(I18N.SAAS_DATA_QUERY_ROWS),
                key: "queryRows",
                style: {
                    width: '78px'
                }
            }, {

                name: i18n(I18N.SAAS_DATA_LINKED_DASHBOARD),
                key: "dbPath"
            }, {
                name: i18n(I18N.SAAS_DATA_UPDATE_TIME),
                key: "updateTime",
                style: {
                    width: '148px'
                }
            }
        ];
        this.COLUMNINFO_DATASOURCE = [
            {
                name: i18n(I18N.SAAS_DATA_CONN_NAME),
                key: "name",
            },
            {
                name: i18n(I18N.SAAS_DATA_CONN_TYPE),
                key: "type",
            }, {
                name: i18n(I18N.SAAS_DATA_CONN_PATH),
                key: "path",
            }, {
                name: i18n(I18N.SAAS_DATA_LINKED_QUERY),
                key: "queryPaths",
            }, {
                name: i18n(I18N.SAAS_DATA_UPDATE_TIME),
                key: "updateTime",
            }
        ];
        this.state = {
            itemList: [],
            totalSize: 0,
            totalPage: 0,
            currPage: 0,
            queryTotalRows: 0,
        };
    }

    render() {
        let {itemList, totalPage, totalSize, currPage} = this.state;
        const lang = userLanguage();
        let otherLangClass = "";

        if(lang !== "zh_CN") {
            otherLangClass = " btn-lang";
        }

        return (
            <>
                <NavigateBar/>
                <div className='content'>
                    <div className='data_Container'>
                        <div>
                            <button className={`${this.activeTable === "query" ? 'btn-active' : 'btn-default'}` + otherLangClass}
                                    onClick={() => this.changeActiveTab("query")}
                            >{i18n(I18N.SAAS_SYS_QUERY)}</button>
                            <button className={`${this.activeTable === "dataSource" ? 'btn-active' : 'btn-default'}` + otherLangClass}
                                    onClick={() => this.changeActiveTab("dataSource")}
                            >{i18n(I18N.SAAS_SYS_CONN)}</button>
                            {this.activeTable === "query" ? <span className="totalSize">
                            {i18n(I18N.SAAS_DATA_TOTAL_ROWS)}：{this.state.queryTotalRows}
                            </span> : null}
                        </div>
                        <div className='Tem_Item'>
                            {
                                !itemList ? <Loader key="loader_loading" type='loading'/> :
                                    (itemList.length === 0 ? this.renderEmpty() : (
                                        <PagingTable key={"page-element"}
                                                     columnInfo={this.state.columnInfo}
                                                     onSelectChange={this.onSelectChange}
                                                     dataList={this.state.itemList}
                                                     actions={[{
                                                         label: i18n(I18N.SAAS_SYS_DELETE),
                                                         style: {width: '80px'},
                                                         icon: deleteIcon,
                                                         action: (data) => this.deleteSingle(data),
                                                     }]}
                                        />
                                    ))
                            }
                        </div>
                        { !itemList ? null : (itemList.length === 0 ? null : <div hidden={totalSize <= 0} style={{display: "flex", paddingTop: "20px"}}>
                            <button type="button" ref={this.btnRef} disabled
                                    className={"batch-delete btn-submit"}>
                                {i18n(I18N.SAAS_DATA_BATCH_DELETE)}
                            </button>
                            <Pagination ref={(ref)=>this.pageRef=ref}
                                        pageCallback={this.changeCurrentPage}
                                        pageSizeCallback={this.pageSizeCallback}
                                        totalSize={totalSize}
                                        totalPage={totalPage}
                                        pageSize={this.PAGE_SIZE}
                                        currPage={this.CURR_PAGE}/>
                        </div>)}
                    </div>
                </div>
                <Footer/>
            </>
        );
    }

    changeActiveTab(name) {
        if(this.activeTable === name){
            return;
        }

        this.CURR_PAGE = 1;
        this.activeTable = name;

        if(name === "query") {
            this.getTotalRows();
            this.getItemList();
        }
        else if(name === "dataSource") {
            this.initDataSourcePage();
        }

        this.onSelectChange([]);
    }

    deleteSingle = (item={}) => {
        const items = [item];

        if(this.activeTable === "query") {
            this.fnDelete(items);
        }
        else if(this.activeTable === "dataSource") {
            this.fnDeleteDataSource(items);
        }
    };

    deleteBatch = () => {
        const items = this.selectedItems;

        if(this.activeTable === "query") {
            this.fnDelete(items);
        }
        else if(this.activeTable === "dataSource") {
            this.fnDeleteDataSource(items);
        }
    };

    fnDeleteDataSource = (items=[]) => {
        const _that = this;
        Alert.open({
            title: i18n(I18N.SAAS_SYS_TIPS),
            type: 'warning',
            context: `<span style="color: #666666; font-size: 14px; height: 22px">${i18n(I18N.SAAS_DATA_QUERY_DEL_SOURCETIPS)}</span>`,
            callBackOk: function(){
                _that.fnPostDeleteDataSource(items);
            }
        })
    };

    fnPostDeleteDataSource = (items = []) => {
        let ids = [];
        items.forEach(item => {
            ids.push(item.id);
        });

        axios.post("/saas/data/delete.do", ids)
            .then(res => {
                const data = res['data'];
                if(data['status'] === 'success') {
                    this.initDataSourcePage();
                }
                else {
                    Tips.error(data['message'] || i18n(I18N.SAAS_DATA_DELETE_FAIL));
                }
            });
    };

    onSelectChange = (items = []) => {
        this.selectedItems = items;
        let itemList = this.state.itemList;

        if(!itemList || itemList.length === 0) {
            return;
        }

        this.btnRef.current.removeEventListener('click', this.deleteBatch);

        if(this.selectedItems.length > 0) {
            this.btnRef.current.addEventListener('click', this.deleteBatch);
            this.btnRef.current.removeAttribute("disabled")
        }
        else {
            this.btnRef.current.setAttribute("disabled", true)
        }
    };

    componentWillMount() {
        this.getTotalRows();
        this.getItemList();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderEmpty = () => {
        return (
            <div style={{
                'textAlign': 'center',
                'position': 'fixed',
                'top': '50%',
                'left': '50%',
                'transform': 'translate(-50%,-50%)'
            }}>
                <div className="empty">
                    <div className="data_empty"/>
                    <span>{i18n(I18N.SAAS_DATA_EMPTY_TIPS)}</span>
                </div>
            </div>)
    };

    getItemList = (type) => {
        let params = {
            pageSize: this.PAGE_SIZE,
            pageNum: this.CURR_PAGE,
        };

        axios.post('/saas/getResInfo.do', {}, {params})
            .then(res => {
                const data = res.data;
                if(data['status'] === 'success') {
                    if(data.data.total % this.PAGE_SIZE === 0 && type === "delete") {
                        this.CURR_PAGE = this.CURR_PAGE - 1;
                    }
                    
                    this.setState(
                        {
                            columnInfo: this.COLUMNINFO_QUERY,
                            itemList: data.data.list || [],
                            totalPage: data.data.pages,
                            totalSize: data.data.total
                        });
                }
                else {
                    Tips.error(`${i18n(I18N.SAAS_DATA_QUERY_FAIL)}[${data['message'] || i18n(I18N.SAAS_SYS_ERROR)}]`)
                }
            }).catch(e => {
            Tips.error(i18n(I18N.SAAS_SYS_ERROR));
        });
    };

    getTotalRows = () => {
        axios.post('/saas/getTotalRows.do')
            .then(res => {
                const data = res.data;

                if(data['status'] === 'success') {
                    this.setState(
                        {
                            queryTotalRows: data.data,
                        });
                }
                else {
                    Tips.error(`${i18n(I18N.SAAS_DATA_QUERY_FAIL)}[${data['message'] || i18n(I18N.SAAS_SYS_ERROR)}]`)
                }

            }).catch(e => {
            Tips.error(i18n(I18N.SAAS_SYS_ERROR));
        });
    };

    fnDelete = (item) => {
        let queryPath = [];

        if(item.length === 0) {
            return Alert.open({
                title: i18n(I18N.SAAS_SYS_TIPS),
                type: 'warning',
                context: `<span style="color: #666666; font-size: 14px; height: 22px">${i18n(I18N.SAAS_DATA_DELETE_EMPTY_TIPS)}</span>`,
            })
        }

        item.forEach((o, i) => {
            queryPath[i] = o.queryPath + "/" + o.queryName + "_" + o.queryType;
        });

        Alert.open({
            title: i18n(I18N.SAAS_SYS_TIPS),
            type: 'warning',
            context: `<span style="color: #666666; font-size: 14px; height: 22px">${i18n(I18N.SAAS_DATA_QUERY_DEL_TIPS)}</span>`,
            callBackOk: function() {
                axios.post("/saas/delQuery.do", queryPath)
                    .then(res => {
                        const data = res['data'];
                        if(data['status'] === 'success') {
                            _that.getItemList("delete");
                            _that.getTotalRows();
                        }
                        else {
                            Tips.error(data['message'] || i18n(I18N.SAAS_DATA_DELETE_FAIL));
                        }
                    });
            }
        })
    };

    changeCurrentPage = (currPage) => {
        this.CURR_PAGE = currPage;
        this.pageEventHandler();
    };

    pageSizeCallback = (pageSize) => {
        this.PAGE_SIZE = pageSize;
        this.CURR_PAGE = 1;
        this.pageEventHandler();
    };

    pageEventHandler = () => {
        this.activeTable === "query" ? this.getItemList() : this.initDataSourcePage()
    };

    initDataSourcePage = () => {
        let params = {
            pageSize: this.PAGE_SIZE,
            pageNum: this.CURR_PAGE,
        };
        axios.post('/saas/data/pageInfo.do', {}, {params})
            .then(res => {
                const data = res.data;
                if(data['status'] === 'success') {
                    const itemList = data['data']['list'];
                    this.setState({
                        itemList,
                        columnInfo: this.COLUMNINFO_DATASOURCE,
                        totalPage: data.data.pages,
                        totalSize: data.data.total
                    })
                }
                else {
                    Tips.error(`${i18n(I18N.SAAS_DATA_QUERY_FAIL)}[${data['message'] || i18n(I18N.SAAS_SYS_ERROR)}]`)
                }
            })
            .catch(e => {
                console.error(e);
                Tips.error(i18n(I18N.SAAS_SYS_ERROR));
            });
    };
}