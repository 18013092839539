import React, {Component} from 'react';
import './Alert.scss';
import {ReactSVG} from "react-svg";
import close from "../../assets/images/saas/close.svg"
import {fnIsMobile} from "../Util/tool";
import I18N, {i18n} from "../Util/I18nUtils";

let _that;
export default class Alert extends Component {
    constructor(props) {
        super(props);
        _that = this;
    }

    state = {
        isOpen: false,
    };

    options = {
        title: i18n(I18N.SAAS_SYS_TIPS),
        // 类型
        type: 'success',
        //显示的内容支持html
        context: '内容',
        //点击确定按钮回调函数
        callBackOk: null,
        //点击取消按钮回调函数
        callBackCancel: null,
        //点击右上角关闭按钮回调函数
        callBackClose: null
    };


    render() {
        let {title, type} = this.options;
        let isMobile = fnIsMobile();
        return (
            <div hidden={!this.state.isOpen} className="mask" id="mask">
                {isMobile ? this._fnRenderMobile(title, type) : this._fnRenderPC(title, type)}
            </div>
        )
    };

    // pc端的展示
    _fnRenderPC = (title, type) => {
        return (<div className="alert">

            <div className="alert_head">
                <h3>{title}</h3>
                <a href="#" onClick={this.fnClose}>
                    <ReactSVG src={close} className="alert_close_button"/>
                </a>
            </div>
            <div className="alert_body">
                <div className="alert_body-dom">
                    <div className={`alert_icon icon_${type || 'success'}`}/>
                    <div id="alert_body"/>
                </div>
            </div>
            <div className="alert_footer">
                <div className="alert_btn">
                    <button className="btn_cancel" onClick={this.fnCancel}>{i18n(I18N.SAAS_SYS_CANCEL)}</button>
                    <button className="btn_ok" onClick={this.fnOk}>{i18n(I18N.SAAS_SYS_AFFIRM)}</button>
                </div>
            </div>
        </div>)
    };

    // 移动端的展示
    _fnRenderMobile = (title, type) => {
        return (<div className="alert-mobile">
            <div className="alert-mobile-body">
                <div id="alert_body" className="alert-mobile-title"/>
            </div>
            <div className="alert-mobile-operate">
                <button className="alert-mobile-operate-cancel" onClick={this.fnCancel}>{i18n(I18N.SAAS_SYS_CANCEL)}</button>
                <button className="alert-mobile-operate-ok" onClick={this.fnOk}>{i18n(I18N.SAAS_SYS_AFFIRM)}</button>
            </div>
        </div>)
    };

    fnOk = () => {
        const callback = this.options.callBackOk;
        if(callback !== null && typeof callback == "function") {
            callback()
        }
        this.setState({
            isOpen: false
        })
    };

    fnCancel = () => {
        const callback = this.options.callBackCancel;
        if(callback !== null && typeof callback == "function") {
            callback()
        }
        this.setState({
            isOpen: false
        })
    };

    fnClose = (e) => {
        if(e != null) {
            e.preventDefault();
        }
        const callback = this.options.callBackClose || this.options.callBackCancel;
        if(callback !== null && typeof callback == "function") {
            callback()
        }
        this.setState({
            isOpen: false
        })
    };

    static open = (options) => {
        _that.options = Object.assign({}, _that.options, options);
        _that.setState({
            isOpen: true
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        document.getElementById("alert_body")
            .innerHTML = this.options.context
    }

    componentDidMount() {
    }

};