import React, { Component } from 'react';
import './Loader.scss';
import I18N, {i18n} from "../Util/I18nUtils";

export default class Loader extends Component {
  render() {
    let { type } = this.props;
    let loader;
    if (type === 'loading') {
      loader = (
        <div className='loader' key={0}>
          <div className='loader-loadingGif'/>
          <div>{i18n(I18N.SAAS_SYS_LOADING_WAITE)}</div>
        </div>
      );
    }
    if (type === 'noMoreData') {
      loader = (
        <div className='loader'>
          <div>{i18n(I18N.SAAS_SYS_NO_MORE)}</div>
        </div>
      );
    }
    if (type === 'dataNull') {
      loader = (
        <div className='loader dataNull'>
          <div>{i18n(I18N.SAAS_SYS_NOT_FIND)}</div>
        </div>
      );
    }
    return loader;
  }
}
