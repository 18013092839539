import React, {Component} from 'react';
import iconLeft from './../../assets/images/icon_left.svg';
import iconRight from './../../assets/images/icon_right.svg';
import './Pagination.scss';
import Select from 'react-select';
import I18N, {i18n} from "../Util/I18nUtils";

let _that;
export default class Pagination extends Component {
    constructor(props) {
        super(props);
        _that = this;
        this.options = [
            {value: 15, label: `15${i18n(I18N.SAAS_SYS_PAGE_PAGING)}`},
            {value: 20, label: `20${i18n(I18N.SAAS_SYS_PAGE_PAGING)}`},
            {value: 50, label: `50${i18n(I18N.SAAS_SYS_PAGE_PAGING)}`},
            {value: 100, label: `100${i18n(I18N.SAAS_SYS_PAGE_PAGING)}`}
        ];
    }

    state = {
        currPage: this.props.currPage,
        totalPage: this.props.totalPage,
        totalSize: this.props.totalSize,
        pageSize: this.props.pageSize,

        selectedValue: {
            value: this.props.pageSize,
            label: this.props.pageSize + i18n(I18N.SAAS_SYS_PAGE_PAGING)
        }
    };

    render() {
        let {currPage, totalPage} = this.state;
        let firstBtnClass = `pagination-btn ${currPage == 1 ? 'pagination-disabled' : null}`;
        let lastBtnClass = `pagination-btn ${currPage == totalPage ? 'pagination-disabled' : null}`;
        let buttonImgStyle = {width: '10px', height: '20px', marginTop: '5px'};

        return (
            <div className='page_box'>
                <span className={firstBtnClass} onClick={this.prePageHandler}>
          <img src={iconLeft} style={buttonImgStyle} alt=''/>
        </span>
                <input className='pagenum_pagination_input' value={currPage}
                       id="pageNum"
                       onKeyPress={this.fn_pageNumOnPress}
                       onChange={this._fnOnChangePageNum}/>
                <span className="pageFont">/</span><span className="pageFont">{totalPage}</span>
                <span className={lastBtnClass} onClick={this.nextPageHandler}>
                <img src={iconRight} style={buttonImgStyle} alt=''/>
                </span>
                <div style={{"marginRight": '-4px', 'marginLeft': '8px', 'text_align': 'center'}}>
                    <Select className="react-select-container"
                            classNamePrefix="react-select-"
                            options={this.options}
                            isSearchable={false}
                            value={this.state.selectedValue}
                            onChange={this.pageSizeClick}
                            menuPlacement="top"
                    />
                </div>
            </div>
        );
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            currPage: nextProps.currPage,
            totalPage: nextProps.totalPage,
            totalSize: nextProps.totalSize,
            pageSize: nextProps.pageSize,

            selectedValue: {
                value: nextProps.pageSize,
                label: nextProps.pageSize + i18n(I18N.SAAS_SYS_PAGE_PAGING)
            }
        })
    }

    /**
     * 控制只能输入数字
     * @param e
     */
    fn_pageNumOnPress = (e) => {
        const key = e.key;
        if(key.replace(/\D/g, '') === '') {
            e.preventDefault();
        }
    };

    _fnOnChangePageNum = (e) => {
        let pageNum = e.target.value;
        if(pageNum.startsWith('0')) {
            pageNum = pageNum.replace(0, '')
        }
        if(pageNum === "" || (pageNum >= 1 && pageNum <= this.state.totalPage)) {
            this.pageClick(pageNum);
        }
    };

    // 点击页码
    pageClick = (currPage) => {
        const getcurrPage = this.props.pageCallback;

        this.setState({currPage},
            getcurrPage(currPage),
        );
    };

    // 点击分页
    pageSizeClick = (obj) => {
        const pageSize = Number(obj.value);
        const pageSizeCallback = this.props.pageSizeCallback;

        if(pageSize === this.state.selectedValue.value){
            return;
        }

        this.setState({
                pageSize: pageSize,
                currPage: 1,
                selectedValue: {
                    value: pageSize,
                    label: pageSize + i18n(I18N.SAAS_SYS_PAGE_PAGING)
                }
            }, () => {
                pageSizeCallback(pageSize);
            }
        );
    };

    //上一页事件
    prePageHandler = () => {
        let {currPage} = this.state;
        if(currPage === "" || Number(currPage) <= 1) {
            return false;
        }

        this.pageClick(currPage - 1);
    };

    //下一页事件
    nextPageHandler = () => {
        let {currPage, totalPage} = this.state;
        currPage = Number(currPage);
        if(currPage === "" || currPage === 0) {
            currPage = 1;
        }
        if(currPage + 1 > totalPage) {
            return false;
        }

        this.pageClick(currPage + 1);
    };

    componentDidMount() {
        const dom = document.getElementById("pageNum");
        if(dom != null) {
            dom.onblur = function(e) {
                if(!e.target.value || Number(e.target.value) < 1) {
                    _that.setState({currPage: 1},function() {
                        e.target.value = 1;
                    });

                }
            }
        }
    }
}
