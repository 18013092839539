import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ScrollToTop from './../src/components/Util/ScrollToTop';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// 将配置文件挂载到全局
React.Component.prototype.$config = window.publicConfig

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('saas-front-root'),
);

