import React, {Component} from 'react';
import NavigateBar from '../../components/NavigateBar/NavigateBar';
import './Reports.scss';
import axios from '../../service/http';
import Report from '../../components/Report/Report';
import ModalBox from "../../components/ModalBox/ModalBox";
import Alert from "../../components/Alert/Alert";
import Tips from "../../components/Tips/Tips";
import Footer from "../../components/Footer/Footer";
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../../components/Loader/Loader';
import {fnIsMobile} from "../../components/Util/tool";
import Switch from "react-switch";
import copy from 'copy-to-clipboard';
import I18N, {i18n} from "../../components/Util/I18nUtils";

let _that;
export default class index extends Component {

    constructor(props) {
        super(props);
        _that = this;
        this.state = {
            timer: null,
            visible: false,
            reportUrl: '',
            currPage: 1,
            totalSize: 0,
            pageSize: PAGE_SIZE,
            itemList: null,
            shareInfo: {
                shareStatus: 0
            },
            checked: {
                isPwdChecked: false,
                isOutDaysChecked: false,
                isShareClubChecked: false,
                isPwd: false,
                isOutDays: false
            }
        };
        this.loading = true;
    }

    render() {
        let {itemList, shareInfo, hasMore} = this.state;
        const disableStyle = {
            "color": "#b8b8b8"
        };
        const footerStyle = {};
        return (
            <>
                <NavigateBar/>
                <div id="_scroll" className='content home-content'
                     ref={(ref) => this.scrollParentRef = ref}>
                    <InfiniteScroll
                        initialLoad={true}
                        pageStart={1}
                        loadMore={() => this.getItemList()}
                        hasMore={hasMore}
                        useWindow={false}
                        getScrollParent={() => this.scrollParentRef}
                        loader={<Loader key="loader_loading" type='loading'/>}>
                        <div className='Report_Tem_Container'>
                            <div className='reports_Item'>
                                {itemList == null ? <Loader key="loader_loading" type='loading'/> :
                                    itemList.length === 0 ? this.renderEmpty() : this.renderList(itemList)
                                }
                                {itemList && itemList.length > 0 && !hasMore ?
                                    <Loader key="loader_noMoreData" type='noMoreData'/> : null
                                }
                            </div>
                        </div>
                    </InfiniteScroll>
                </div>

                <ModalBox
                    title={i18n(I18N.SAAS_REPORT_SHARE)}
                    width="600px"
                    visible={this.state.visible}
                    footer={false}
                    footerStyle={footerStyle}
                    fnOk={this.fnCancel}
                    fnCancel={this.fnCancel}
                    fnClose={this.fnCancel}
                >
                    <div className="operate">
                        <form id="_form">
                            <div>
                                <label>
                                    {i18n(I18N.SAAS_REPORT_PUBLIC_SHARE)}&nbsp;&nbsp;
                                    <Switch
                                        checked={this.state.shareInfo['shareStatus'] === 1}
                                        onChange={this.fnChangeShareStatus}
                                        className="react-switch"
                                        // disabled={this.state.timer !== null}
                                        height={fnIsMobile() ? 20 : 16}
                                        width={fnIsMobile() ? 40 : 32}
                                        offColor="#BFBFBF"
                                        onColor="#19af5d"
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                    />
                                </label>
                            </div>
                            <hr/>
                            <div style={this.state.shareInfo['shareStatus'] !== 1 ? disableStyle : null}>
                                <label><input type="checkbox" name="shareByPwd" id="isPwd"
                                              onClick={(e) => this.fnChangeCheckBox(e, 'isPwd')}
                                              disabled={this.state.shareInfo['shareStatus'] !== 1}
                                              checked={this.state.checked['isPwdChecked'] || false}/>
                                    {i18n(I18N.SAAS_REPORT_ENCRYPTED_SHARE)}</label>
                                <input type="text" id="sharePwd" placeholder={i18n(I18N.SAAS_REPORT_PWD_PLACEHOLDER)}
                                       style={this.state.shareInfo['shareStatus'] !== 1 ? disableStyle : null}
                                       disabled={!this.state.checked.isPwd}
                                       hidden={!this.state.checked.isPwd}
                                       onKeyPress={this._fnOnKeyPressPwd}
                                       onChange={this._fnOnChange}
                                       maxLength="8"
                                       defaultValue={shareInfo['sharePwd'] || ''}/>
                            </div>

                            <div style={this.state.shareInfo['shareStatus'] !== 1 ? disableStyle : null}>
                                <label><input type="checkbox" id="isOutDays"
                                              onClick={(e) => this.fnChangeCheckBox(e, 'isOutDays')}
                                              disabled={this.state.shareInfo['shareStatus'] !== 1}
                                              checked={this.state.checked['isOutDaysChecked'] || false}/>
                                    {i18n(I18N.SAAS_REPORT_OUT_DAYS)}</label>
                                <input type="number" defaultValue={shareInfo['outDays'] || '7'}
                                       style={this.state.shareInfo['shareStatus'] !== 1 ? disableStyle : null}
                                       disabled={!this.state.checked.isOutDays}
                                       readOnly={!this.state.checked.isOutDays}
                                       min="0"
                                       max="365"
                                       maxLength="3"
                                       onInput={this.fnOutDaysOnInput}
                                       onKeyPress={this.fnOutDaysOnKeyPress}
                                       placeholder={i18n(I18N.SAAS_REPORT_DAYS_PLACEHOLDER)} id="outDays"/>&nbsp;{i18n(I18N.SAAS_REPORT_DAY)}
                            </div>

                            <div className="link">
                                <input id="reportUrl"
                                       disabled={this.state.shareInfo['shareStatus'] !== 1}
                                       value={this.state.reportUrl} readOnly/>
                                {fnIsMobile() ? <br/> : null}
                                <button style={{"marginRight": "8px"}}
                                        className="btn_copy_link"
                                        disabled={this.state.shareInfo['shareStatus'] !== 1}
                                        onClick={(e) => this.fnCopy(e)}>{i18n(I18N.SAAS_REPORT_COPY_URL)}
                                </button>
                                <button disabled={this.state.shareInfo['shareStatus'] !== 1}
                                        onClick={this.fnOpenShareDB}>{i18n(I18N.SAAS_REPORT_OPEN_URL)}
                                </button>
                            </div>
                            {this.state.shareInfo['shareStatus'] !== 1 ? null :
                                <div className="share-qr-code" id="share-qr-code">
                                    {this.createQRCode(this.state.reportUrl)}
                                </div>
                            }
                            {/*<hr style={{"marginTop": "16px"}}/>*/}
                            {/*<div className="share-club">*/}
                            {/*    <div style={{"margin": "0"}}>*/}
                            {/*        <label*/}
                            {/*            style={this.state.shareInfo['shareStatus'] !== 1 ? {"color": "#888888"} : null}>*/}
                            {/*            <input type="checkbox" name="shareClub" id="shareClub"*/}
                            {/*                   style={{"marginRight": "8px"}}*/}
                            {/*                   onClick={(e) => this.fnChangeCheckBox(e, 'isShareClub')}*/}
                            {/*                   disabled={this.state.shareInfo['shareStatus'] !== 1}*/}
                            {/*                   checked={this.state.checked['isShareClubChecked'] || false}/>*/}
                            {/*            同步到永洪社区*/}
                            {/*        </label>*/}
                            {/*        {this.state.shareInfo['clubUrl'] ?*/}
                            {/*            <a href={this.state.shareInfo['clubUrl']} target="_blank"*/}
                            {/*               className="share-club-link">查看帖子</a> : null}*/}
                            {/*    </div>*/}
                            {/*    <p hidden={this.state.shareInfo['shareStatus'] !== 1}>*/}
                            {/*        开启后，此报告将以帖子的形式分享到永洪社区，以供大家学习和交流，*/}
                            {/*        同时您将获得30积分的奖励，每账号每月7此奖励上限，*/}
                            {/*        关闭后，已分享的帖子将会删除*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                        </form>
                    </div>
                </ModalBox>

                <div className="report_foot">
                    <Footer/>
                </div>
            </>
        );
    }

    createQRCode = (url) => {
        return (<>
            <img id="qr-code-img" src={"/saas/comm/qrCode.do?url=" + url}
                 width={fnIsMobile() ? "90px" : "88px"}
                 height={fnIsMobile() ? "90px" : "88px"}
                 alt={i18n(I18N.SAAS_REPORT_QRCODE_SHARE)}/>
            <div className="share-qr-txt">
                <span>{i18n(I18N.SAAS_REPORT_QRCODE_SHARE)}</span>
                {fnIsMobile() ? this.renderMobilAction() : null}
            </div>
        </>)
    };

    renderMobilAction = () => {
        return (<>
            <button type="button"
                    onClick={() => this.fnSaveQrCode()}>{i18n(I18N.SAAS_REPORT_QRCODE_IMG_SAVE)}
            </button>
        </>)
    };

    fnSaveQrCode = () => {
        const a = document.createElement("a");
        a.href = "/saas/comm/qrCode.do?url=" + this.state.reportUrl;
        a.click();
    };

    renderEmpty = () => {
        const style = {
            'textAlign': 'center',
            'position': 'fixed',
            'top': '50%',
            'left': '50%',
            'transform': 'translate(-50%,-50%)'
        };
        return (
            <div style={style}>
                <div className="empty">
                    <div className="reports_empty"/>
                    <span>{i18n(I18N.SAAS_REPORT_EMPTY_TIPS)}</span>
                </div>
            </div>
        )
    };

    renderList = (itemList) => {
        itemList = itemList || [];
        return itemList.map((item, index) => (
            <Report
                key={index}
                id={item.id}
                updateDate={item.updateDate}
                name={item['dbPath']}
                path={item['path']}
                type={item['dbType']}
                isVip={this.isVip(item['vipExpireTime'])}
                isExtract ={item['isExtract'] !== null && item['isExtract'] === 0}
                isShared={item['shareId'] !== null && item['shareId'] !== ''}
                fnShare={(e) => this.fnShare(e, item.id)}
                fnDelete={(e) => this.fnDelete(e, item)}
            />
        ))
    };

    componentDidMount() {
        this.getItemList();
        document.onclick = this._fnCloseMoreAction
    }

    _fnOnKeyPressPwd = (e) => {
        if(e != null) {
            const key = e.key;
            if(!key.match(/[a-zA-Z0-9]/)) {
                e.preventDefault();
            }
        }
    };

    isVip = (vipExpireTime) => {
        if(!vipExpireTime) {
            return false;
        }

        return vipExpireTime && new Date(vipExpireTime) > new Date();
    };

    /**
     * 防止复制粘贴空格，或者特殊字符
     * @param e
     * @private
     */
    _fnOnChange = (e) => {
        if(e != null) {
            const value = e.target.value || '';
            e.target.value = value.replace(/[^a-zA-Z0-9]/g, "");

            _that._fnDeBounceOk();
        }
    };

    _fnCloseMoreAction = (e) => {
        const currentTmpDom = document.getElementById("currentReportId");
        let currentId = '';
        if(currentTmpDom != null) {
            currentId = currentTmpDom.value;
            currentTmpDom.value = null;
        }

        const allReport = document.getElementById("allReportId");
        if(allReport != null) {
            let allIds = allReport.value;
            const idList = allIds.split(",");
            idList.forEach(id => {
                if(id !== currentId && id !== '') {
                    allIds = allIds.replace("," + id, "");
                    const close = document.getElementById(id);
                    if(close != null) {
                        close.style.visibility = 'hidden'
                    }
                }
            });
            allReport.value = allIds;
        }

        const _closeReport = document.getElementById("_closeReport");
        if(_closeReport != null) {
            const id = _closeReport.value;
            const hidden = document.getElementById(id);
            if(hidden != null) {
                hidden.style.visibility = "hidden";
                _closeReport.value = "";
            }
        }
    };

    // 1、按下但是未给input赋值
    fnOutDaysOnKeyPress(e) {
        if(e.key === '+' || e.key === '-') {
            e.preventDefault();
        }
    }

    ///2、已经给input赋值了
    fnOutDaysOnInput(e) {
        e.target.value = e.target.value.slice(0, 3);

        _that._fnDeBounceOk();
    }

    fnChangeCheckBox = (e, key) => {
        let update;
        if(key === 'isPwd') {
            update = {
                isPwd: e.target.checked
            };
            this.state.checked['isPwdChecked'] = e.target.checked;
        }
        else if(key === "isOutDays") {
            update = {
                isOutDays: e.target.checked
            };
            this.state.checked['isOutDaysChecked'] = e.target.checked;
        }
        else if(key === "isShareClub") {
            update = {
                isShareClub: e.target.checked
            };
            this.state.checked['isShareClubChecked'] = e.target.checked;
        }
        this.state.checked = Object.assign(this.state.checked, update);
        this.setState(this.state.checked);

        _that._fnDeBounceOk()
    };

    fnChangeShareStatus = async(value) => {

        if(this.state.timer) {
            return;
        }

        const checked = value;
        let shareStatus = 0;
        let update;
        if(checked) {
            shareStatus = 1;
            update = this.state.preChecked;

            // 重新打开后，重新获取一个链接url
            const uri = await this.fnGetSaasUrl(this.state.selectedReportId);
            _that.setState({
                shareInfo: {
                    ..._that.state.shareInfo,
                    saasUrl: uri
                }
            })
        }
        // 关闭分享时，将输入密码，过期时间，置为disable
        else {
            // 记录关闭前的状态
            const preChecked = this.state.checked;
            this.setState({
                preChecked
            });
            update = {
                isPwd: false,
                isOutDays: false
            };
        }
        const shareInfo = Object.assign({}, this.state.shareInfo, {shareStatus: shareStatus});
        const up = Object.assign({}, this.state.checked, update);
        this.setState({shareInfo, checked: up}, function() {
            if(checked) {
                document.getElementById("qr-code-img").style.animation = "showQrCode .3s linear";
                document.getElementById("share-qr-code").style.animation = "showQrCode .3s linear";
            }
        });

        _that._fnDeBounceOk(20);
    };

    fnOpenShareDB = (e) => {
        if(e != null) {
            e.preventDefault();
        }
        const url = document.getElementById("reportUrl").value;
        window.open(url)
    };

    fnConvertPathAndName(item) {

        let fullPath = item['dbPath'];
        let splitChar = "/";
        const isWindowsPath = this.fnBoolIsWindowsPath(fullPath);
        if(isWindowsPath) {
            splitChar = "\\";
        }
        fullPath = fullPath.replace(splitChar + splitChar, "");
        const paths = fullPath.split(splitChar) || [];
        let path = '';
        for(let i = 1; paths.length > 1 && i < paths.length - 1; i++) {
            path += splitChar + paths[i];
        }

        // 瀵瑰簲report缁勪欢涓殑path
        item['path'] = i18n(I18N.SAAS_SYS_ROOT_PATH) + path;
        // 瀵瑰簲report缁勪欢涓殑name
        item['dbPath'] = this.fnConvertPathToName(item['dbPath']);
    }

    // 判断是否是windows路径
    fnBoolIsWindowsPath = (path) => {
        return path.indexOf("\\") > -1;
    };

    fnConvertPathToName = (dbPath) => {
        let name = dbPath;

        //Linux path
        if(dbPath.indexOf("/") > -1) {
            name = dbPath.substr(dbPath.lastIndexOf("/") + 1);
        }
        //windows path
        else if(dbPath.indexOf("\\") > -1) {
            name = dbPath.substr(dbPath.lastIndexOf("\\") + 1);
        }
        if(dbPath.indexOf('.db') > -1) {
            name = dbPath.substr(0, dbPath.lastIndexOf(".db"));
        }

        return name;
    };

    fnCheckValue = () => {
        let result;
        let status = true;
        // 校验页面选择数据
        const isPwd = document.getElementById('isPwd').checked;
        const isOutDays = document.getElementById('isOutDays').checked;
        const isShareClub = false;//document.getElementById('shareClub').checked;
        // 校验是否开启分享
        const shareStatus = this.state.shareInfo.shareStatus === 1;
        let sharePwd = document.getElementById("sharePwd").value;
        if(isPwd && shareStatus) {
            if(sharePwd == null || sharePwd === '' || sharePwd.replace(/\s+/g, "") === '') {
                sharePwd = null;
            }
        }
        else {
            sharePwd = null;
        }

        let outDays = document.getElementById("outDays").value;
        if(isOutDays && shareStatus) {
            if(outDays == null || outDays === '') {
                outDays = null;
            }
            else if(outDays <= 0 || outDays > 365) {
                const resetOutDays = function() {
                    document.getElementById("outDays").value = _that.state.shareInfo.outDays;
                };
                Alert.open({
                    type: 'warning',
                    context: i18n(I18N.SAAS_API_OUT_DAYS_TIPS, 0, 365),
                    callBackOk: resetOutDays(),
                    callBackCancel: resetOutDays(),
                    callBackClose: resetOutDays()
                });
                status = false;
            }
        }
        else {
            outDays = null;
        }
        result = {
            status: status,
            body: {
                sharePwd: sharePwd,
                outDays: outDays,
                isShareClub: isShareClub
            }
        };
        return result;
    };

    /**
     * 防抖
     * @param timeout
     * @private
     */
    _fnDeBounceOk = (timeout = 250) => {
        if(this.state.timer) {
            clearTimeout(this.state.timer);
        }

        const timer = setTimeout(() => {
            _that.fnOk();
            clearTimeout(timer);
            _that.setState({timer: null});
        }, timeout);

        this.setState({timer: timer});
    };

    fnOk = () => {

        if(this.state.selectedReportId === null) {
            return Tips.error(i18n(I18N.SAAS_SYS_ERROR))
        }
        const result = this.fnCheckValue();
        if(!result['status']) {
            return;
        }

        const body = Object.assign({}, this.state.shareInfo, {
            dashboardId: this.state.selectedReportId,
            shareStatus: this.state.shareInfo.shareStatus
        }, result['body']);
        //点击确定后，将分享相关信息发送给后端
        axios.post('/saas/share/save.do', body)
            .then((res) => {
                const data = res.data;
                if(data['status'] === 'success') {
                    // Tips.success(`${body.shareStatus > 0 ? '' : '关闭'}分享成功`);
                    // 操作成功后，直接在前端修改状态，不再次查询数据，
                    // 由于前端未使用分页，所以不能查询数据，否则页面数据就不对了，删除报告逻辑一样
                    this.state.itemList.forEach((item) => {
                        if(item['id'] === this.state.selectedReportId) {
                            if(body['shareStatus'] === 0) {
                                item['shareId'] = null;
                            }
                            else {
                                item['shareId'] = data.data ? data.data.id : new Date().getTime();
                            }
                            this.setState({itemList: this.state.itemList});
                        }
                    });

                    const shareInfo = Object.assign({}, _that.state.shareInfo, data['data']);
                    this.setState({
                        shareInfo: shareInfo
                    })
                }
                else {
                    Alert.open({
                        type: 'warning',
                        context: data['message'] || i18n(I18N.SAAS_REPORT_SHARE_FAIL)
                    });
                }
            })
            .catch((err) => {
                // Tips.error(err || '分享失败');
                console.log(err)
            });
    };
    /**
     * 关闭弹出框
     */
    fnCancel = (e) => {
        if(e != null) {
            e.preventDefault();
        }
        document.getElementById("_form").reset();
        this.setState({
            visible: false,
            selectedReportId: null,
            reportUrl: '',
            shareInfo: {}
        })
    };

    fnShare = (e, id) => {
        if(e != null) {
            e.preventDefault();
        }
        this.setState({
            selectedReportId: id
        });
        // 获取报表信息并回显到分享页面
        axios.get("/saas/share/getShareInfo.do", {params: {id: id}})
            .then(async res => {
                const data = res.data;
                if(data['status'] === 'success') {
                    const isShared = data['data'] != null;
                    let checked = {
                        isPwd: false,
                        isOutDays: false
                    };
                    let saasUrl = null;
                    if(isShared) {
                        const isPwd = data['data']['sharePwd'] != null;
                        const isOutDays = data['data']['outDays'] != null;
                        checked = {
                            isPwd: isPwd,
                            isOutDays: isOutDays,
                            isPwdChecked: isPwd,
                            isOutDaysChecked: isOutDays
                        };
                        // 前端拼接saas url地址
                        let saasUrl = window.location.protocol + "//" + window.location.host;
                        saasUrl += data.data['saasUrl'];

                        this.setState({
                            reportUrl: saasUrl
                        })
                    }
                    // 如果没被分享，则去获取一个uri地址
                    else {
                        saasUrl = await this.fnGetSaasUrl(id);
                    }
                    const shareInfo = Object.assign({}, data['data'], {shareStatus: data['data'] == null ? 0 : 1});
                    if(saasUrl != null) {
                        shareInfo['saasUrl'] = saasUrl;
                    }
                    this.setState({
                        visible: true,
                        shareInfo: shareInfo || {},
                        checked: checked
                    });
                }
                else {
                    Tips.error(res['message'])
                }
            })
    };

    fnGetSaasUrl = async(id) => {
        let uri = null;
        await axios.get("/saas/share/getSaasUri.do", {params: {id: id}})
            .then(res => {
                const data = res.data;

                if(data['status'] === 'success') {
                    uri = data.data;
                    // 前端拼接saas url地址
                    let saasUrl = window.location.protocol + "//" + window.location.host;
                    saasUrl += data.data;

                    this.setState({
                        reportUrl: saasUrl
                    })
                }
                else {
                    Tips.error(data['message'] || i18n(I18N.SAAS_SYS_ERROR))
                }
            });
        return uri;
    };

    fnCopy = (e) => {
        if(e != null) {
            e.preventDefault();
        }
        const url = document.getElementById("reportUrl").value;
        const password = this.state.shareInfo.sharePwd;
        const text = i18n(I18N.SAAS_REPORT_SHARE_URL) + " : " + url
            + (password == null || password === "" ? "" : "\r\n" + i18n(I18N.SAAS_REPORT_SHARE_PWD) + " : " + password);
        copy(text);
        Tips.success(i18n(I18N.SAAS_REPORT_SHARE_COPY_SUCCESS));
    };

    fnDelete = (e, item) => {
        if(e != null) {
            e.preventDefault();
        }
        const id = item['id'];
        const _that = this;

        // 发送请求删除报表
        Alert.open({
            title: i18n(I18N.SAAS_SYS_CONFIRM),
            type: 'warning',
            context: fnIsMobile() ? i18n(I18N.SAAS_REPORT_DELETE_MOBILE_TIPS) : `${i18n(I18N.SAAS_REPORT_DELETE_TIPS)}${item['dbPath']}?`,
            callBackOk: function() {
                axios.post("/saas/db/delete.do", [id])
                    .then(res => {
                        const data = res['data'];
                        if(data['status'] === 'success') {
                            _that.state.itemList.forEach((o, i) => {
                                if(o['id'] === item['id']) {
                                    _that.state.itemList.splice(i, 1);
                                    _that.setState(_that.state.itemList);
                                }
                            });
                        }
                        else {
                            Tips.error(data['message'] || i18n(I18N.SAAS_REPORT_DELETE_FAIL));
                        }
                    });
            }
        })
    };

    getItemList = () => {
        let params = {
            pageSize: this.state.pageSize,
            pageNum: this.state.currPage++,
        };

        axios.post('/saas/db/pageInfo.do', {}, {params})
            .then(res => {
                const data = res.data;
                if(data['status'] === 'success') {
                    data.data.list.forEach((item) => {
                        this.fnConvertPathAndName(item);
                    });
                    const itemList = this.state.itemList || [];
                    this.setState(
                        {
                            itemList: [...itemList, ...data.data.list],
                            totalPage: data.data.pages,
                            totalSize: data.data.total,
                            hasMore: data.data.hasNextPage
                        }
                    );
                }
                else {
                    Tips.error(`${i18n(I18N.SAAS_REPORT_QUERY_FAIL)}[${data['message'] || i18n(I18N.SAAS_SYS_ERROR)}]`)
                }

            }).catch(e => {
            Tips.error(e.message || i18n(I18N.SAAS_SYS_ERROR));
        });
    };

    changeCurrentPage = (currPage) => {
        this.setState({currPage}, this.getItemList);
    };

    pageSizeCallback = (pageSize) => {
        this.setState({pageSize}, this.getItemList);
    }
}
const PAGE_SIZE = 40;