import React, {Component} from 'react';
import './ModalBox.scss';
import {ReactSVG} from "react-svg";
import close from "../../assets/images/saas/close.svg"
import {fnIsMobile} from "../Util/tool";
import pagePre from "../../assets/images/saas/page-pre.svg"
import I18N,{i18n} from "../Util/I18nUtils";

export default class ModalBox extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        isDrag: false,
    };

    render() {
        const {visible} = this.props;
        return (
            <div className="modal_mask" id="modal_mask" hidden={!visible}>

                {fnIsMobile() ? this._fnRenderMobile(this.props) : this._fnRenderPc(this.props)}
            </div>
        );
    }

    _fnRenderPc = (props) => {
        const {title, fnOk, fnCancel, fnClose, width, height, footer, footerStyle} = props;

        const style_content = {
            width: width,
            height: height
        };
        return (<div id="modal_content"
                     className="modal_content"
                     style={style_content}
        >
            <div>
                <div className="modal_head"
                    // onMouseDown={() => this.fnMouseDown()}
                    // onMouseUp={() => this.fnMouseUp()}
                >
                    <h3>{title}</h3>
                    <a href="#" onClick={fnClose}>
                        <ReactSVG src={close} className="share_close_button"/>
                    </a>
                </div>

                <div id="modal_body">
                    {this.props.children}
                </div>

                {footer !== false ? (
                    <div className="modal_footer" style={footerStyle}>
                        <div className="btn">
                            <button id="cancel" onClick={fnCancel}>{i18n(I18N.SAAS_SYS_CANCEL)}</button>
                            <button id="ok" onClick={fnOk}>{i18n(I18N.SAAS_SYS_AFFIRM)}</button>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>)
    };

    _fnRenderMobile = (props) => {
        const {title, fnOk, fnCancel, fnClose, width, height, footerStyle} = props;
        return (<div id="modal-content-mobile" className="modal-mobile-content">
            <div>
                <div className="modal-mobile-head">
                    <div className="modal-mobile-head-back">
                        <a href="/" onClick={fnCancel}>
                            <ReactSVG src={pagePre} wrapper="span" className="modal-mobile-head-back-icon"/>
                        </a>
                    </div>
                    {title}
                    <div className="modal-mobile-head-ok">
                        <a href="#" onClick={fnOk}>{i18n(I18N.SAAS_SYS_AFFIRM)}</a>
                    </div>
                </div>
                <hr className="modal-mobile-content-hr"/>
                <div id="modal_body" className="modal-mobile-content-body">
                    {this.props.children}
                </div>
            </div>
        </div>)
    };

    fnMouseDown = () => {
        this.setState({
            isDrag: true
        })
    };

    fnMouseMove = (e) => {
        const element = document.getElementById("modal_content");
        const rect = element.getBoundingClientRect();

        if(this.state.isDrag) {
            const translateX = rect.x + e.movementX;
            const translateY = rect.y + e.movementY;
            element.style.top = 'unset';
            element.style.left = 'unset';
            element.style.transform = "translateX(" + translateX + "px) translateY(" + translateY + "px)";

        }
    };

    fnMouseUp = () => {
        this.setState({
            isDrag: false
        });
    };

    componentDidMount() {
        // document.addEventListener('mousemove', this.fnMouseMove);
        // document.addEventListener('mouseup', this.fnMouseUp);
    }
};