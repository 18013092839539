import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import I18N , {i18n} from "../../components/Util/I18nUtils";

export default class NotFound extends Component {
    render() {
        return (
            <div style={{textAlign: 'center'}}>
                <h1 >404 Not Found</h1>
                <Link to="/dashboard">{i18n(I18N.SAAS_SYS_BACK_INDEX)}</Link>
                <hr/>
            </div>
        );
    }
}
