import React, {Component} from 'react';
import './PagingTable.scss';
import ReactTooltip from 'react-tooltip';
import {ReactSVG} from "react-svg"
import I18N, {i18n} from "../Util/I18nUtils";

export default class PagingTable extends Component {
    constructor(props) {
        super(props);
        this.columnInfo = this.props['columnInfo'] || [];
        this.dataList = this.props['dataList'] || [];
        this.actionStyle = this.props['actionStyle'] || {};
        this.actions = this.props['actions'] || [];
        this.onSelectChange = this.props['onSelectChange'] || null;
        this.headerStyle = {};
        this.rowStyle = {};
        this.state = {
            isSelectedAll: false,
            selectedItem: [],
        }
    }


    render() {
        return (<>
            <ReactTooltip
                type="light"
                delayShow={500}
                border={true}
                backgroundColor='white'
                multiline={true}
                className="react-tip-class"
                place='bottom'
                getContent={this._fnGetContent}
            />

            <table className={"paging-table"}>
                <thead style={this.headerStyle}>
                <tr>
                    <th className={`head-checkbox`}>
                        <input className={this.partSelectedFlag() ? 'select-part' : null}
                               readOnly
                               onClick={this.selectAllClick}
                               checked={this.allSelectedFlag() || this.partSelectedFlag()} type="checkbox"/>
                    </th>
                    {this.columnInfo.map((item, index) => {
                        return <th style={item.style} key={`h_${index}`} className={"table-header"}>{item.name}</th>
                    })}
                    <th style={this.actionStyle} className={"table-header-action"}>{i18n(I18N.SAAS_DATA_OPERATE)}</th>
                </tr>
                </thead>
                <tbody>
                {this.dataList.map((rowData, ri) => {
                    return <tr style={this.rowStyle}
                               key={`row_${ri}`}
                               className={`row-style ${this.checkedFlag(rowData) ? 'row-checked' : ''}`}
                               onClick={() => this.rowClick(rowData)}>
                        <td className={"row-checkbox"}>
                            <input type="checkbox" readOnly checked={this.checkedFlag(rowData)}/>
                        </td>
                        {this.columnInfo.map((item, ci) => {
                            return <td key={`row_${ri}_col_${ci}`} data-tip={rowData[item.key]}
                                       style={item.style}>{rowData[item.key]}</td>
                        })}
                        <td style={{width: this.actionStyle['width'] || "100px", minWidth: "100px"}}>
                            <div>
                                {this.actions.map((item, ai) => {
                                    return <a style={item['style']} className={"click-action"}
                                              key={`row_${ri}_ac_${ai}`}
                                              onClick={(e) => this.actionClick(e, item['action'], rowData)}>
                                        <ReactSVG className={"action-icon"} src={item.icon}/>{item.label}
                                    </a>

                                })}
                            </div>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </>);
    }

    /**
     * 只有出现省略号才显示tip....
     * @param text
     * @returns {null|DataTable._fnGetContent|string}
     * @private
     */
    _fnGetContent = (text) => {
        const event = window.event;
        if(event != null) {
            const dom = event.target;
            if(dom != null) {
                if(dom.scrollWidth > dom.offsetWidth) {
                    return text;
                }
                return null;
            }
        }
        return text;
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.columnInfo = nextProps['columnInfo'] || [];
        this.dataList = nextProps['dataList'] || [];
        this.actionStyle = nextProps['actionStyle'] || {};
        this.actions = nextProps['actions'] || [];
        this.onSelectChange = nextProps['onSelectChange'] || null;

        this.setState({
            isSelectedAll: false,
            selectedItem: [],
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const changeCallback = this.onSelectChange;
        changeCallback && typeof changeCallback === "function" && changeCallback(this.state.selectedItem);
    }

    selectAllClick = () => {
        let items = Object.assign([], this.dataList);
        this.allSelectedFlag() && (items = []);
        this.setState({
            selectedItem: items
        });
    };

    partSelectedFlag = () => {
        return this.state.selectedItem.length < this.dataList.length && this.state.selectedItem.length > 0;
    };

    allSelectedFlag = () => {
        return this.state.selectedItem.length === this.dataList.length;
    };

    checkedFlag = (rowData) => {
        return this.state.selectedItem.indexOf(rowData) > -1
    };

    rowClick = (rowData) => {
        let selectedItem = this.state.selectedItem;
        const index = this.state.selectedItem.indexOf(rowData);
        index >= 0 && selectedItem.splice(index, 1);
        index < 0 && selectedItem.push(rowData);
        this.setState({selectedItem});
    };

    actionClick = (e, fun, data) => {
        e.stopPropagation();
        if(typeof fun === "function") {
            fun(data);
        }
    };
}