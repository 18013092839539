import React, {Component} from 'react';
import './Share.scss';
import Cookie from 'react-cookies';
import md5 from 'js-md5';
import axios from "../../service/http";
import {fnIsMobile, fnIsUrl} from "../../components/Util/tool";
import QRCode from 'qrcode.react';
import {ReactSVG} from "react-svg";
import iconClose from '../../assets/images/saas/icon-close.svg';
import share_link from '../../assets/images/saas/icon-link.svg'
import share_qrcode from '../../assets/images/saas/icon-qrcode.svg'
import share_icon from '../../assets/images/saas/icon-share.svg'
import Tips from "../../components/Tips/Tips";
import copy from 'copy-to-clipboard';
import I18N, {i18n} from "../../components/Util/I18nUtils";
import wx from "weixin-js-sdk";

let _that;
export default class Share extends Component {
    constructor(props) {
        super(props);
        _that = this;
    }

    state = {
        isMove: false,
        reportUrl: '',
        advUrl: ''
    };

    render() {
        const url = this.state.reportUrl;
        return (<div id="saas-share-db-dom" style={{"height": "100%", "overflow": "hidden"}}>
            <iframe id="bi-db-frame" allowFullScreen frameBorder="no"
                    className="bi-db-frame" src={url} scrolling="no"/>
            {this.renderAdv()}
        </div>);
    }

    renderAdv = () => {
        return (<>
            {this.renderBanner()}
            <div id="adv-temp-mask"
                 style={this.state.isMove ? {"visibility": "visible"} : {"visibility": "hidden"}}
                 className="adv-share-mask"/>
            {fnIsMobile() ? null : this.renderShare()}
        </>)
    };

    renderBanner = () => {
        const saasAdvBg = {
            backgroundImage: 'url(' + this.state.advImg + ')' +
                ',linear-gradient(to right, ' + this.state.advColor + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
        };
        return (<div id="saas-adv" style={saasAdvBg} className="saas-adv"
                     onClick={(e) => this.fnOpenAdvUrl(e, this.state.advUrl)}>
            <a target="_blank" className="saas-adv-close"
               onClick={this.advCloseHandler}>
                <ReactSVG src={iconClose} wrapper="svg"
                          className="saas-adv-close-ico"/>
            </a>
        </div>);
    };

    renderShare = () => {
        return (<div className="adv-share" id="adv-share">
            <div className="adv-share-action">
                <ReactSVG src={share_link} wrapper="div"
                          onMouseUp={() => this.fnCopyUrl()}
                          className="adv-share-ico adv-share-link"
                          style={{"--tips":`'${i18n(I18N.SAAS_REPORT_COPY_URL)}'`}}/>
                <div className="adv-share-dom">
                    <ReactSVG src={share_qrcode} wrapper="svg"
                              className="adv-share-ico adv-share-qrcode"/>
                    <div className="adv-share-qrcode-img">
                        <QRCode
                            id={window.location.href}
                            value={window.location.href}
                            size={88}
                            fgColor="#000000"
                        />
                        <p>{i18n(I18N.SAAS_REPORT_SCAN_QRCODE)}</p>
                    </div>
                </div>
            </div>
            <ReactSVG onMouseDown={this.fnMouseDown}
                      onMouseMove={this.fnMouseMove}
                      onMouseUp={this.fnMouseUp}
                      src={share_icon} className="adv-share-svg" id="adv-share-svg"/>
        </div>);
    };

    fnMouseDown = (e) => {
        // 屏幕大小
        this.clientWidth = document.body.clientWidth;
        this.clientHeight = document.body.clientHeight;

        // 计算鼠标相对于拖拽dom的位置
        const dom = document.getElementById("adv-share");
        const c_x = 44 - (e.clientX - dom.offsetLeft - 108);
        const c_y = 44 - (e.clientY - dom.offsetTop);
        this.c_x = c_x;
        this.c_y = c_y;

        this.setState({
            isMove: true
        });
    };

    fnMouseUp = (e) => {
        this.setState({
            isMove: false
        });

    };

    fnMouseMove = (e) => {
        if(this.state.isMove && e) {
            const dom = document.getElementById("adv-share");
            let right = this.clientWidth - e.clientX - this.c_x;
            let bottom = this.clientHeight - e.clientY - this.c_y;
            const canMoveX = right > 0 && right < this.clientWidth - 44;
            const canMoveY = bottom > 0 && bottom < this.clientHeight - 44;

            if(canMoveX) {
                if(right >= this.clientWidth - 152) {
                    dom.style.right = "unset";
                    dom.style.left = 0 + 'px';
                }
                else {
                    dom.style.left = "unset";
                    dom.style.right = right + 'px';
                }
            }
            if(canMoveY) {
                dom.style.bottom = bottom + 'px';
            }
        }
    };

    fnCopyUrl = () => {
        const _that = this;
        if(this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            copy(window.location.href);
            Tips.success(i18n(I18N.SAAS_REPORT_SHARE_COPY_SUCCESS));
            _that.timer = null;
        }, 200);

    };
    fnOpenAdvUrl = (e, url) => {
        if(e != null) {
            e.preventDefault();
            // 防止点击事件线上传递[点击button会执行两次该方法]
            e.stopPropagation();
        }
        window.open(url);
    };

    advCloseHandler = (e) => {
        e.preventDefault();
        this.fnRemoveAdv(e);

        // add cookie，同一个报表，同一个浏览器，24小时内不再展示广告
        const expires = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        let cookieName = this.fnGetAdvCookieName();
        Cookie.save(cookieName, "false", {expires: expires})
    };

    fnRemoveAdv(e) {
        if(e != null) {
            e.preventDefault();
            // 防止点击事件线上传递[点击button会执行两次该方法]
            e.stopPropagation();
        }
        const adv = document.getElementById("saas-adv");
        if(adv) {
            adv.remove();
        }
    }

    fnGetAdvCookieName() {
        // 记录广告关闭cookieName __adv__ 加上 url进行md5加密后的字符串
        return "__adv__";
    }

    fnSetAdvLinkUrl() {
        // 首先从浏览器cookie和localStorage中获取数据
        const advUrl = Cookie.load("__adv_link_url__");
        const advImg = localStorage.getItem("__adv_link_img__");
        const color = localStorage.getItem("__adv_link_color__");

        if(advUrl != null && fnIsUrl(advUrl)
            && color != null && color !== ''
            && advImg != null && advImg.indexOf("data:image/jpeg;base64,") > -1) {
            this.setState({
                advImg: advImg,
                advUrl: advUrl,
                advColor: color
            });
            return;
        }

        axios.get("/saas/comm/banner.do").then(function(res) {
            res = res.data;
            if(res['status'] === 'success') {
                const url = res['data']['url'];
                const img = res['data']['img'];
                const color = res['data']['color'];

                _that.setState({
                    advUrl: url,
                    advImg: img,
                    advColor: color
                }, function() {
                    Cookie.save("__adv_link_url__", url);
                    localStorage.setItem("__adv_link_img__", img);
                    localStorage.setItem("__adv_link_color__", color);
                });
            }
        }).catch(function(err) {
            console.log(err)
        })
    }

    fnSetFrameUrl() {
        let search = window.location.search;

        if(search && search !== "") {
            let url = search.replace("?url=", "");
            // iframe中的url为绝对地址，不需要http头
            url = url.replace(/http[s]?:\/\/.*?\//g, "/");

            // 防止url拼接时出现不同源的问题
            if(url && url.length > 0) {
                this.setState({
                    reportUrl: url
                }, function() {
                    // 修改浏览器地址栏的地址，但不刷新页面
                    url = url.replace(/\?.*/g, "");
                    window.top.history.replaceState('', '', url);
                });
            }
            else {
                console.log("Invalid URL: " + url);
                window.location.href = '/'
            }
        }
        else {
            console.log("Invalid search:" + search);
            window.location.href = '/'
        }
    }

    fnSetDomStyle() {
        const body = document.getElementById("saas-front-body");
        const root = document.getElementById("saas-front-root");
        const app = document.getElementById("saas-front-app");
        const domList = [body, root, app];

        domList.forEach(function(element) {
            if(element != null) {
                element.style.height = "100%";
            }
        });
    }

    fnTryToHiddenAdv() {
        const cookieName = this.fnGetAdvCookieName();
        const cookieValue = Cookie.load(cookieName);

        if(cookieValue != null && cookieValue === "false") {
            this.fnRemoveAdv();
            return;
        }

        // set adv link url
        this.fnSetAdvLinkUrl();
    }

    fnChangeHtmlTitle() {
        const biFrame = document.getElementById("bi-db-frame");
        let frameDom = biFrame.contentWindow.document;

        biFrame.onload = function() {
            if(frameDom.readyState === "complete") {
                // 这里需要重新获取一次才能获取到加载完成后的数据
                frameDom = biFrame.contentWindow.document;

                let title = frameDom.title;
                title = title != null && title.length > 0 ? title : i18n(I18N.SAAS_SYS_NAME);
                document.title = title;
                // iframe加载完成再去集成微信SDK
                wx.ready(function() {
                    _that.fnLoadShareUserInfo()
                });

                wx.error(function(res) {
                    if(res['errMsg'] === 'config:invalid signature') {
                        const urls = res['realAuthUrl'];
                        if(urls !== null && urls.length > 0) {
                            const url = urls[0];
                            _that.fnInitWeiXinConfig(url)
                        }
                    }
                });

                _that.fnInitWeiXinConfig(window.location.href);
            }
        }
    }

    fnInitWeiXinConfig(url) {
        axios.get("/saas/comm/sdk/config.do", {params: {url: url}})
            .then(res => {
                const data = res.data;
                if(data['status'] === 'success') {
                    const WeiXinConfig = data['data'];

                    const config = {
                        // debug: true,
                        jsApiList: ['updateAppMessageShareData','onMenuShareAppMessage'],
                    };

                    Object.assign(config, WeiXinConfig);
                    wx.config(config);
                }
            });
    }

    fnLoadShareUserInfo(){
        let shareUrl = window.location.pathname;
        const prefixIndex = shareUrl.indexOf("/s/");
        shareUrl = shareUrl.substr(prefixIndex);

        axios.get('/saas/share/getShareUser.do',{params: {shareURI:shareUrl}})
            .then(res=>{
                const data = res.data;

                if(data['status'] === 'success') {
                    const username = data['data'] ? data['data']['username'] : '';
                    const content = `${username}-${i18n(I18N.SAAS_REPORT_SHARE_SUCCESS)}`;
                    _that.fnSetShareMetaInfo(content);

                    const options = {
                        title: document.title,
                        desc: content,
                        link: window.location.href,
                        imgUrl: `${window.location.protocol}//${window.location.host}/logo.png`,
                    };

                    _that.fnInitWeiXinShareData(options);
                }
            });
    }

    fnSetShareMetaInfo(content){
        const description = window.top.document.getElementById("metaDescription");
        description.content = content;
    }

    fnInitWeiXinShareData(options) {
        // 判断当前客户端是否支持当前api
        wx.checkJsApi({
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
            success: function(res) {
                console.log("check api success", res);
                wx.updateAppMessageShareData(options);
                wx.updateTimelineShareData(options);
            },
            fail: function(err) {
                alert("check api error");
                console.error("check api error", err);
            }
        });
    }

    componentWillMount() {
        this.fnSetFrameUrl();
    }

    componentDidMount() {
        this.fnSetDomStyle();
        this.fnTryToHiddenAdv();
        this.fnChangeHtmlTitle();
        document.addEventListener("mousemove", this.fnMouseMove);
        document.addEventListener("mouseup", this.fnMouseUp);
    }
}