import React, {Component} from 'react';
import NavigateBar from '../../components/NavigateBar/NavigateBar';
import Footer from '../../components/Footer/Footer';
import './HelpCenter.scss';
import help_step_1 from '../../assets/images/saas/help_step_1.svg'
import help_step_2 from '../../assets/images/saas/help_step_2.svg'
import help_step_3 from '../../assets/images/saas/help_step_3.svg'

import jp_step_1 from '../../assets/images/saas/vividata/help_step_1.svg';
import jp_step_2 from '../../assets/images/saas/vividata/help_step_2.svg';
import jp_step_3 from '../../assets/images/saas/vividata/help_step_3.svg';

import us_step_1 from '../../assets/images/saas/vivime/1.svg';
import us_step_2 from '../../assets/images/saas/vivime/2.svg';
import us_step_3 from '../../assets/images/saas/vivime/3.svg';
import desktop_icon from '../../assets/images/saas/vividata/desktop_icon.svg';
import market_icon from '../../assets/images/saas/vividata/market_icon.svg';
import I18N, {i18n} from "../../components/Util/I18nUtils";
import {userLanguage} from "../../components/Util/tool";
import icon_right from "../../assets/images/saas/icon_right.svg";
import {ReactSVG} from "react-svg";
import Cookie from "react-cookies";

export default class HelpCenter extends Component {
    state = {
        lang:'zh_CN',
        step: {
            "zh_CN": [help_step_1, help_step_2, help_step_3],
            "ja_JP": [jp_step_1, jp_step_2, jp_step_3],
            "en_US": [us_step_1, us_step_2, us_step_3],
        },

        operation_url: {
            "zh_CN": 'https://www.yonghongtech.com/real-help/Z-Suite/10.2/ch/desktop-yonghongyun.html',
            // "en_US": 'https://www.vividime.com/real-help/Z-Suite/10.0/ch/desktop-yonghongyun.html',
            "en_US": 'https://www.vividime.com/help/10.2/en/how-to-upload-desktop-reports2.html',
        },

        operation_url1: {
            "zh_CN": 'https://www.yonghongtech.com/real-help/Z-Suite/10.2/ch/viewdb-sharepublicly.html',
            // "en_US": 'https://www.vividime.com/real-help/Z-Suite/10.0/ch/viewdb-sharepublicly.html',
            "en_US": 'https://www.vividime.com/help/10.2/en/public-sharing.html',
        },
        
        help_url: {
            "zh_CN": 'https://www.yonghongtech.com/help/Z-Suite/10.2/ch/',
            // "en_US": 'https://www.vividime.com/help/Z-Suite/10.0/ch/',
            "en_US": 'https://www.vividime.com/help/10.2/en/',
        },

        help_title_url: {
            "zh_CN": 'https://www.yonghongtech.com/cp/desktop/',
            // "en_US": 'https://www.vividime.com/cp/desktop/',
            "en_US": 'https://www.vividime.com/products/desktop/',
        }
    };

    render() {
        return (
            <>
                <NavigateBar/>
                <div className="help_body">
                    <div className="help_body_tips">
                        <div className="help_process">
                            <span>{i18n(I18N.SAAS_HELP_PROCESS)}</span>
                        </div>
                        <div className="help_more">
                            <a onClick={() => this.openUrl(this.state.help_url[this.state.lang])} href="#">
                                {i18n(I18N.SAAS_HELP_MORE)}
                                <ReactSVG
                                src={icon_right}
                                className="svg_css"/>
                            </a>
                        </div>
                    </div>
                    <div className={"noTips"}>
                    <div className='help_content'>
                        <div className="help_step">
                            <div className="help_title">
                                <span className="step_num">{i18n(I18N.SAAS_HELP_STEP_FIRST)}</span>
                                <div className="step_break"/>
                                <span className="step_info">{i18n(I18N.SAAS_HELP_STEP_FIRST_TIPS)}</span>
                                <div className="step_break"/>
                                <a onClick={() => this.openUrl(this.state.help_title_url[this.state.lang])} href="#">{i18n(I18N.SAAS_DOWNLOAD)}</a>
                            </div>
                            <img
                                src={this.state.step[this.state.lang][0]}
                                className="help_icon_style"
                            />
                        </div>
                        <div className="help_step_split">
                        </div>
                        <div className="help_step">
                            <div className="help_title">
                                <span className="step_num">{i18n(I18N.SAAS_HELP_STEP_SECOND)}</span>
                                <div className="step_break"/>
                                <span className="step_info">{i18n(I18N.SAAS_HELP_STEP_SECOND_TIPS)}</span>
                                <div className="step_break"/>
                                <a onClick={() => this.openUrl(this.state.operation_url[this.state.lang])} href="#">{i18n(I18N.SAAS_OPERATION_PROCESS)}</a>
                            </div>
                            <img
                                src={this.state.step[this.state.lang][1]}
                                className="help_icon_style"
                            />
                        </div>
                        <div className="help_step_split">
                        </div>
                        <div className="help_step">
                            <div className="help_title">
                                <span className="step_num">{i18n(I18N.SAAS_HELP_STEP_THIRD)}</span>
                                <div className="step_break"/>
                                <span className="step_info">{i18n(I18N.SAAS_HELP_STEP_THIRD_TIPS)}</span>
                                <div className="step_break"/>
                                <a onClick={() => this.openUrl(this.state.operation_url1[this.state.lang])} href="#">{i18n(I18N.SAAS_OPERATION_PROCESS)}</a>
                            </div>
                            <img
                                src={this.state.step[this.state.lang][2]}
                                className="help_icon_style"
                            />
                        </div>
                    </div>
                    <div className="help_body_recommend">
                        <div className="help_process">
                            <span>{i18n(I18N.SAAS_RELATED_RECOMMEND)}</span>
                        </div>
                        <div className={"recommend-content"}>
                            <div className={"recommend-item"}>
                                <a onClick={() => this.openUrl(this.state.help_title_url[this.state.lang])} href="#">
                                <img
                                    src={desktop_icon}
                                    className="recommend_icon_style"
                                />
                                <div className="item_break"/>
                                <span className="item_name">Desktop</span>
                                </a>
                                <div className="item_break"/>
                                <span className="item_des">{i18n(I18N.SAAS_DESKTOP_DESC)}</span>
                            </div>
                            <div className="recommend_step_split">
                            </div>
                            <div className={"recommend-item"} style={{display: this.state.lang === "en_US"? "none" : null}}>
                                <a onClick={() => this.openUrl("https://plugins.yonghongtech.com/templates")} href="#">
                                <img
                                    src={market_icon}
                                    className="recommend_icon_style"
                                />
                                <div className="item_break"/>
                                <span className="item_name">{i18n(I18N.SAAS_HELP_MARKET)}</span>
                                </a>
                                <div className="item_break"/>
                                <span className="item_des">{i18n(I18N.SAAS_MARKET_DESC)}</span>
                            </div>
                        </div>

                    </div>
                    </div>
                </div>
                {/*<div className="help_more">*/}
                {/*    <div className="help_more_auto">*/}
                {/*        <a className="help_more_info"*/}
                {/*           href="https://club.yonghongtech.com/forum-61-1.html"*/}
                {/*           target="_blank">{i18n(I18N.SAAS_HELP_PRODUCT_MORE)} ></a>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <Footer/>
            </>
        );
    }

    openUrl(url){
        let uid = Cookie.load('user_id');
        let code = Cookie.load('user_code')
        let reportUrl = url + "?uid=" + uid + "&code=" + code;
        window.open(reportUrl);
    }

    componentDidMount() {
        const lang = userLanguage();

        this.setState({
            lang: lang
        })
    }

}
