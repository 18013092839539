import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import axios from './http';
import Tips from "../components/Tips/Tips";
import Cookie from 'react-cookies';
import I18N, {i18n} from "../components/Util/I18nUtils";
import {isEmptyString} from "../components/Util/tool";

const DATA = {
    token: localStorage.getItem('token'),
    username: localStorage.getItem('username'),
};

const AuthRouter = (props) => {
    const {component: Component, needLogin} = props;
    if(needLogin === false || isLogin()) {
        return <Route key={props.location.pathname} {...props} component={Component}/>;
    }
    else {
        console.log(props);
        
        if('/signUp' === props.location.pathname) {
            localStorage.setItem("signUp", true);
        }
        
        return <Redirect exact {...props} from={props.path} to={'/login'}/>;
    }
};

const isLogin = () => {
    const token = Cookie.load('token');
    return token != null && token !== "undefined" && token !== "null";
};

const autoLoginSaas = async() => {
    await axios.post('/saas/checkLogin.do')
        .then(res => {
            const data = res.data;

            if(data['status'] === 'success') {
                let isLogin = data.data.isLogin;
                let username = data.data.username;
                let marketname = data.data.marketname;

                if(isLogin === false) {
                    let saas_login = data.data.saas_login;
                    localStorage.removeItem('username');
                    Cookie.remove('token');
                    window.location.href = saas_login;
                }

                Cookie.save("token", data.data.token, {path: '/'});
                if(isLogin === true) {
                    localStorage.setItem('username', username);
                    localStorage.setItem('userid', username);
                    localStorage.setItem('marketname', marketname);
                    localStorage.setItem('loginUrl', data.data.saas_login);
                    Cookie.save("token", data.data.token, {path: '/'});
                    
                    if(localStorage.getItem('signUp')) {
                        window.location.href = "http://saas.yonghongtech.com/signUp";
                        localStorage.removeItem('signUp');
                    }
                    else {
                        window.location.href = "/";
                    }
                }
            }
            else {
                Tips.error(`${i18n(I18N.SAAS_SYS_LOGIN_FAIL)}[${data['message'] || i18n(I18N.SAAS_SYS_ERROR)}]`)
            }

        }).catch(e => {
            Tips.error(i18n(I18N.SAAS_SYS_ERROR));
        });
};

const logout = () => {
    axios.get('/saas/logoutSaaS.do')
        .then(res => {
            const data = res.data;
            if(data['status'] === 'success') {
                let saas_login = data.data.saas_login;
                clearLocalStorage();
                // 清空cookie信息
                cleanAllCookies();
                window.location.href = saas_login;
            }
            else {
                Tips.error(`${i18n(I18N.SAAS_SYS_LOGOUT_FAIL)}[${data['message'] || i18n(I18N.SAAS_SYS_ERROR)}]`)
            }
        }).catch(e => {
        Tips.error(i18n(I18N.SAAS_SYS_ERROR));
    });
};

const clearLocalStorage = (clearAll = false, ...ignoreCookie) => {
    if(clearAll) {
        localStorage.clear();
        return;
    }

    ignoreCookie = [...(ignoreCookie || []), 'lang', 'local'];
    let saveCookieMap = {};
    for(let ignore of ignoreCookie) {
        const value = localStorage.getItem(ignore);
        if(!isEmptyString(value)) {
            saveCookieMap[ignore] = value;
        }
    }

    localStorage.clear();
    for(let key in saveCookieMap) {
        localStorage.setItem(key, saveCookieMap[key]);
    }
};

/**
 * 清空cookie，包括顶级域名的cookie信息
 */
const cleanAllCookies = () => {
    const all = Cookie.loadAll() || [];
    Object.keys(all).forEach(key => {
        Cookie.remove(key);
    });
    // 删除顶级域名的cookie【!!注意，如果不删除顶级域名，则可以实现自动登录（使用cookie后端自动登录）】
    const topDomain = ".yonghongtech.com";
    Cookie.save('user_id', null, {domain: topDomain, expires: new Date(0)});
    Cookie.save('user_code', null, {domain: topDomain, expires: new Date(0)});
};

const getUsername = async(getId) => {
    let uid = null;
    
    if(!getId) {
        let marketname = localStorage.getItem("marketname");
        uid = marketname !== null ? marketname : localStorage.getItem("username");
    }

    if(uid === null) {
        let params = {
            token: Cookie.load('token')
        };

        await axios.post('/saas/getUserInfo.do', {}, {params})
            .then(data => {
                if(data.data['status'] === 'success') {
                    // console.log(data.data.data['uid'])
                    uid = data.data.data['marketname'] && !getId ? data.data.data['marketname'] : data.data.data['username'];
                }
                else {
                    Tips.error(`${i18n(I18N.SAAS_SYS_LOGIN_FAIL)}[${data['message'] || i18n(I18N.SAAS_SYS_ERROR)}]`)
                }
            }).catch(e => {
                Tips.error(e.message)
            })
    }

    console.log(uid);
    return uid;
};

const getLoginUrl = async() => {
    let loginUrl = null;

    await axios.post('/saas/getLoginUrl.do')
        .then(data => {
            if(data.data['status'] === 'success') {
                loginUrl = data.data.data['loginUrl'];
            }
            else {
                Tips.error(`${i18n(I18N.SAAS_SYS_LOGIN_FAIL)}[${data['message'] || i18n(I18N.SAAS_SYS_ERROR)}]`)
            }
        }).catch(e => {
            Tips.error(e.message)
        })

    // console.log(loginUrl);
    return loginUrl;
};

const downloadFile = async(id, versionId, isOriginal, isPurchased, name) => {
    axios
        .get(`/applications/${id}/files/${versionId}?isOriginal=${isOriginal}&isPurchased=${isPurchased}`, {
            responseType: 'blob',
        })
        .then((res) => {
            let filename = name + '.jar';
            let blob = new Blob([res.data], {type: 'application/force-download'});
            if(!!window.ActiveXObject || 'ActiveXObject' in window) {
                // IE version
                window.navigator.msSaveBlob(blob, filename);
            }
            else {
                // other versions
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }
        })
        .catch((err) => {
            alert(`${i18n(I18N.SAAS_DOWNLOAD_FAILURE)}`);
        });
};

export {AuthRouter, logout, isLogin, getUsername, downloadFile, autoLoginSaas, getLoginUrl, clearLocalStorage};
