import React, {Component} from 'react';
import './OpenFail.scss'
import Cookie from "react-cookies";
import open_fail from "../../assets/images/saas/vividata/open_fail.svg";
import I18N, {i18n} from "../../components/Util/I18nUtils";

export default class OpenFail extends Component {

    constructor(props) {
        super(props)
    }

    componentWillMount() {
        this.isShare = window.location.pathname === "/share-fail";
        const msg = this.isShare? i18n(I18N.SAAS_REPORT_DATA_FAILURE_SHARE): i18n(I18N.SAAS_REPORT_DATA_FAILURE_NO_VIP);
        this.setState({msg})
    }

    render() {
        return <div className={"fail-content"}>
            <div className={"img-1"}>
                <img
                    src={open_fail}
                    className="recommend_icon_style"
                />
            </div>
            <div>
                <span className={"msg"}>{this.state.msg}</span>
            </div>
            <div className={"action"}>
                <button className={"btn"} hidden={this.isShare}
                        onClick={this.toVip}>开通会员
                </button>
            </div>
        </div>;
    }

    toVip(){
        const buyVIP = window.publicConfig.buy_VIP_url;
        let uid = Cookie.load('user_id');
        let code = Cookie.load('user_code')
        // test
        let reportUrl = buyVIP + "?userId=" + uid + "&code=" + code + "&source=saas";
        //let reportUrl = "https://desktop-pay.yonghongtech.com?userId=" + uid + "&code=" + code + "&source=saas";
        window.open(reportUrl);
    }

    closeTab = () => {
        window.close();
    }
}