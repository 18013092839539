import React, {useEffect} from 'react';
import Reports from './pages/Reports/Reports';
import DataManage from './pages/DataManage/DataManage';
import HelpCenter from './pages/HelpCenter/HelpCenter';
import SignUp from './pages/SignUp/SignUp';
import NotFound from './pages/NotFound/NotFound';
import OpenFail from './pages/Error/OpenFail';
import {Route, Switch, Redirect} from 'react-router-dom';
import axios from './service/http';
import {AuthRouter, isLogin, autoLoginSaas} from './service/Auth';
import Alert from "./components/Alert/Alert";
import Tips from "./components/Tips/Tips";
import Cookie from 'react-cookies';
import Login from "./pages/Login/Login";
import Share from "./pages/Share/Share";
import {fnIsIE} from "./components/Util/tool";
import I18N, {i18n, loadLocalInfo} from "./components/Util/I18nUtils";

function App() {
    const loadLanguageInfo = () => {
        let languageConfig = window.publicConfig.languageConfig
        console.log(languageConfig)
        const version = "1.03";

        if (localStorage.getItem("local_version") != version) {
            localStorage.setItem("local_version", version)
            localStorage.removeItem("local");
        }

        const local = localStorage.getItem("local");

        if (local == null || local === '' || local === undefined) {
            const lang = localStorage.getItem("lang") || languageConfig;
            loadLocalInfo(lang);
        }
    };
    loadLanguageInfo();
    document.title = i18n(I18N.SAAS_SYS_NAME);

    const token = Cookie.load("token");

    useEffect(() => {
        if(token && token !== "null" && token !== "undefined") {
            checkLoginStatus();
        }
    }, []);

    const checkLoginStatus = () => {
        let time = new Date().getTime();
        axios.get(`/saas/check.do?t=${time}`).catch((err) => {
            Cookie.remove('token');
            window.location.reload();
        });
    };

    const ieTips = () => {
        return (<div style={{"textAlign":"center"}}>
            <span>{i18n(I18N.SAAS_IEBROWSER_SPAN1)}</span><br/>
            <span>{i18n(I18N.SAAS_IEBROWSER_SPAN2)}</span><br/>
            <span>Google Chrome、Firefox、Safari、Microsoft Edge</span><br/>
            <span>{i18n(I18N.SAAS_IEBROWSER_SPAN4)}</span><br/>
        </div>)
    };

    return (
        <div id="saas-front-app" className='App'>
            {/*全局引入alert组件，可以直接通过Alert.open({})调用*/}
            <Alert/>

            {/*全局引入Tips组件，可以直接通过Tips.open({})调用*/}
            <Tips/>

            {/* 注册路由 */}
            {fnIsIE() !== -1 ? ieTips() : <Switch>
                <Route path='/login' component={Login}/>
                <AuthRouter needLogin={true} path='/dashboard' component={Reports}/>
                <AuthRouter needLogin={true} path='/data' component={DataManage}/>
                <AuthRouter needLogin={true} path='/help' component={HelpCenter}/>
                <AuthRouter needLogin={true} path='/signUp' component={SignUp}/>
                {/*Redirect注册需要在NotFound之前，否则首先会出现NotFound页面，导致不会跳转Reports首页*/}
                <Redirect exact={true} from='/' to='/dashboard'/>
                <Route path='/share' component={Share}/>
                <Route path='/(open-fail|share-fail)' component={OpenFail}/>
                <Route component={NotFound}/>
            </Switch>}
        </div>
    );
}

export default App;
