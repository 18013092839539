import React, { Component } from 'react';
import './Footer.scss';
import I18N, {i18n} from "../Util/I18nUtils";

class Footer extends Component {
  render() {
    let { style } = this.props;
    return (
      <div className='foot' style={style}>
        <div className='Tem_Container'>
            <div className='beian'>{i18n(I18N.SAAS_SYS_WEB_COPYRIGHT, new Date().getFullYear())}</div>
        </div>
      </div>
    );
  }
}
Footer.defaultProps = {
  style: { float: 'left', height: '80px' },
};

export default Footer;
