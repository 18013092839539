import React, {Component} from 'react';
import {autoLoginSaas} from "../../service/Auth";
import axios from "../../service/http";
import I18N, {i18n} from "../../components/Util/I18nUtils";

export default class Login extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const login_style = {
            textAlign: 'center'
        };

        return (
            <>
                <div style={login_style}>{i18n(I18N.SAAS_SYS_LOGIN_LOADING)}</div>
            </>
        )
    }

    loginSaas() {
        // 模拟登录...没得接口???
        axios.get('/saas/').then(() => {
            // 获取token...
            autoLoginSaas().then();
        });
    }

    componentDidMount() {
        this.loginSaas();
    }
}